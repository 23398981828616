<template>
<v-data-table class="elevation-1" :items="data" :headers="headers" :loading="tableLoading">
    <template v-slot:item.is_unidade_central="{ item }">
        <v-simple-checkbox v-model="item.is_unidade_central" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small class="mr-2" @click="edit(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small @click="remove(item)">
                    mdi-delete
                </v-icon>
            </template>
            <span>Remover</span>
        </v-tooltip>
    </template>
    <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Unidades Organizacionais</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="$state.user.is_superuser" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Novo</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Unidade Organizacional</span>
                    </v-card-title>
                    <ValidationObserver ref="form" v-slot="{ passes }">
                        <v-form @submit.prevent="passes(save)" width="100%">
                            <v-card-text>
                                <v-container>
                                    <ValidationProvider vid="sigla" name="Sigla" v-slot="{ errors }" rules="required">
                                        <v-text-field v-model="Item.sigla" label="Sigla" :error-messages="errors"></v-text-field>
                                    </ValidationProvider>

                                    <ValidationProvider vid="nome" name="Nome" v-slot="{ errors }" rules="required">
                                        <v-text-field v-model="Item.nome" label="Nome" :error-messages="errors"></v-text-field>
                                    </ValidationProvider>

                                    <ValidationProvider vid="is_unidade_central" name="Unidade Central" v-slot="{ errors }">
                                        <v-checkbox v-model="Item.is_unidade_central" label="Unidade Central" :error-messages="errors"></v-checkbox>
                                    </ValidationProvider>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                                <v-btn :loading="saving" color="darken-1" text @click="passes(save)">Salvar</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </v-toolbar>
    </template>
</v-data-table>
</template>

<script>
import {
    UnidadeOrganizacional
} from "@/api/core";
export default {
    name: "UnidadeOrganizacional",
    data: () => ({
        data: [],
        headers: [{
                text: "Sigla",
                value: "sigla",
                align: "center",
            },
            {
                text: "Nome",
                value: "nome",
                align: "center",
                sortable: true,
            },
            {
                text: "Unidade Central",
                value: "is_unidade_central",
                align: "center",
                sortable: true,
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                align: "end",
            },
        ],
        Item: {},
        dialog: false,
        saving: false,
        tableLoading: false,
    }),
    methods: {
        close() {
            this.dialog = false;
            this.$refs.form.reset();
            this.$nextTick(() => {
                this.Item = {};
            });
        },
        save() {
            this.$refs.form.validate().then((valid) => {
                if (valid) {
                    this.saving = true;
                    const _then = () => {
                        this.saving = false;
                        this.close();
                        this.fetch();
                    };
                    const _catch = (error) => {
                        this.saving = false;
                        this.errors = error.response.data;
                        if (this.errors["non_field_errors"]) {
                            this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
                        } else {
                            this.$emitter.emit("alert:error", this.errors);
                        }
                    };

                    if (this.Item["id"]) {
                        let id = this.Item["id"];

                        UnidadeOrganizacional.update(id, this.Item)
                            .then(_then)
                            .catch(_catch);
                    } else {
                        UnidadeOrganizacional.post(this.Item)
                            .then(_then)
                            .catch(_catch);
                    }
                }
            })

        },
        edit(item) {
            this.Item = Object.assign({}, item);
            this.dialog = true;
        },

        remove(item) {
            if (window.confirm("Deseja realmente excluir?")) {
            this.tableLoading = true;
            UnidadeOrganizacional.remove(item["id"]).then(() => {
                this.tableLoading = false;
                this.fetch();
            });
            }
        },
        fetch() {
            this.tableLoading = true;
            UnidadeOrganizacional.list().then((response) => {
                this.data = response.data;
                this.tableLoading = false;
            });
        },
    },
    mounted() {
        this.fetch();
    },
};
</script>
